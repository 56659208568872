import {
  createSlice,
  createAsyncThunk,
  configureStore,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import categoryAndBrandService from "./CatAndBrandService";
import productSlice from "./products/productIndex";
import couponSlice from "./coupon/couponIndex";
import filterSlice from "./products/filterSlice";
import cartSlice from "./cart/cartIndex";
import checkoutSlice from "./checkout/CheckoutIndex";
import orderSlice from "./order/orderIndex";
import transactionSlice from "./transaction/transactionIndex";
import authSlice from "./auth/authIndex";
import subscriberSlice from './subscribers/subscriberindex';
import sliderReducer from './slider/sliderSlice';
import userSlice from "./users/userSlice";
const getErrorMessage = (error) => {
  if (error.response) {
    // Server responded with a status other than 200 range
    return error.response.data.msg || error.response.statusText;
  } else if (error.request) {
    // Request was made but no response was received
    return "No response from server. Please try again later.";
  } else {
    // Something else caused the error
    return error.message;
  }
};

// Category Slice
const initialCategoryState = {
  categories: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const createcategory = createAsyncThunk(
  "category/createcategory",
  async (formData, thunkAPI) => {
    try {
      return await categoryAndBrandService.createcategory(formData);
    } catch (error) {
      return thunkAPI.rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getCategories = createAsyncThunk(
  "category/getCategories",
  async (_, thunkAPI) => {
    try {
      return await categoryAndBrandService.getCategories();
    } catch (error) {
      return thunkAPI.rejectWithValue(getErrorMessage(error));
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "category/deleteCategory",
  async (slug, thunkAPI) => {
    try {
      return await categoryAndBrandService.deleteCategory(slug);
    } catch (error) {
      return thunkAPI.rejectWithValue(getErrorMessage(error));
    }
  }
);

const categorySlice = createSlice({
  name: "category",
  initialState: initialCategoryState,
  reducers: {
    RESET_CAT(state) {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createcategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createcategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload;
        toast.success(action.payload.msg);
      })
      .addCase(createcategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(getCategories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.categories = action.payload.categories;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(deleteCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.isSuccess = true;
        state.isError = false;
        toast.success(action.payload.msg);
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      });
  },
});

// Brand Slice
const initialBrandState = {
  brands: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const createBrand = createAsyncThunk(
  "brand/createBrand",
  async (formData, thunkAPI) => {
    try {
      return await categoryAndBrandService.createBrand(formData);
    } catch (error) {
      return thunkAPI.rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getBrands = createAsyncThunk(
  "brand/getBrands",
  async (_, thunkAPI) => {
    try {
      return await categoryAndBrandService.getBrands();
    } catch (error) {
      return thunkAPI.rejectWithValue(getErrorMessage(error));
    }
  }
);

export const deleteBrand = createAsyncThunk(
  "brand/deleteBrand",
  async (slug, thunkAPI) => {
    try {
      return await categoryAndBrandService.deleteBrand(slug);
    } catch (error) {
      return thunkAPI.rejectWithValue(getErrorMessage(error));
    }
  }
);

const brandSlice = createSlice({
  name: "brand",
  initialState: initialBrandState,
  reducers: {
    RESET_BRAND(state) {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createBrand.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createBrand.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        toast.success(action.payload.msg);
      })
      .addCase(createBrand.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        toast.error(action.payload);
      })
      .addCase(getBrands.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBrands.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
        state.brands = action.payload.brands;
      })
      .addCase(getBrands.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(deleteBrand.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteBrand.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.isSuccess = true;
        state.isError = false;
        toast.success(action.payload.msg);
      })
      .addCase(deleteBrand.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      });
  },
});

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    category: categorySlice.reducer,
    brand: brandSlice.reducer,
    product: productSlice.reducer,
    coupon: couponSlice.reducer,
    filter: filterSlice.reducer,
    cart: cartSlice.reducer,
    checkout: checkoutSlice.reducer,
    order: orderSlice.reducer,
    transaction: transactionSlice.reducer,
    subscribers: subscriberSlice,
    slider: sliderReducer,
    user:userSlice.reducer
  },
});

export const categoryActions = categorySlice.actions;
export const brandActions = brandSlice.actions;

export default store;
