import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userService from "./userServices"; // Import user services
import { toast } from "react-toastify";

const initialUserState = {
  user: null,
  users: [], // Ensure users is an array
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Fetch users
export const getUsers = createAsyncThunk("users/getUsers", async (_, thunkApi) => {
  try {
    const users = await userService.getUsers();
  
    console.log(users);
    
    return users;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.msg) || error.msg || error.toString();
    return thunkApi.rejectWithValue(message);
  }
});

// Delete user
export const deleteUser = createAsyncThunk("users/deleteUser", async (id, thunkApi) => {
  try {
    return await userService.deleteUser(id);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.msg) || error.msg || error.toString();
    return thunkApi.rejectWithValue(message);
  }
});

// Get single user by ID
export const getSingleUser = createAsyncThunk("users/getSingleUser", async (id, thunkApi) => {
  try {
    return await userService.getSingleUser(id);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.msg) || error.msg || error.toString();
    return thunkApi.rejectWithValue(message);
  }
});

// Update user
export const updateUser = createAsyncThunk("users/updateUser", async ({ id, formData }, thunkApi) => {
  try {
    return await userService.updateUser(id, formData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.msg) || error.msg || error.toString();
    return thunkApi.rejectWithValue(message);
  }
});

const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    RESET_USER(state) {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.users = action.payload.users;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(deleteUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.users = state.users.filter((user) => user.id !== action.payload.id);
        toast.success(action.payload.msg);
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload.msg);
      })
      .addCase(getSingleUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSingleUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.user = action.payload; // Store the user we requested
      })
      .addCase(getSingleUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        const index = state.users.findIndex((u) => u.id === action.payload.user.id);
        if (index !== -1) {
          state.users[index] = action.payload.user;
        }
        toast.success(action.payload.msg);
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      });
  },
});

export const userActions = userSlice.actions;
export default userSlice;
