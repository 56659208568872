import React, { useState } from 'react';
import riderImg from '../../assets/delivery.jpg';
import './rider.scss';
import Card from '../../components/ui/card/Card';
import axios from 'axios';
import { toast } from 'react-toastify';

// Modal Component
const Modal = ({ message, onClose }) => {
  return (
    <div className="modal-overlay">
     <Card>
      <div className="modal">
        <h2>{message}</h2>
        <button onClick={onClose} className="--btn --btn-success">
          Close
        </button>
      </div>
      </Card>
    </div>
  );
};

const Rider = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    location: '',
    license: '',
    familiarity: '',
    experience: '',
    whyFit: '',
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
console.log(formData)
  const validateForm = () => {
    const { name, phone, email, location, license, familiarity, experience, whyFit } = formData;
    if (!name || !phone || !email || !location || !license || !familiarity || !experience || !whyFit) {
      setErrorMessage('Please fill in all required fields.');
      toast.error('Please fill in all required fields.');
      return false;
    }
    if (!/^\S+@\S+\.\S+$/.test(email)) {
      setErrorMessage('Please enter a valid email address.');
      toast.error('Please enter a valid email address.');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    try {
      await axios.post('https://blopia.ng/api/v1/application/application', formData, {
        headers: { 'Content-Type': 'application/json' },
      });
      setSuccessMessage('Application submitted successfully!');
      toast.success('Application submitted successfully!');
      
      // Clear form data after successful submission
      setFormData({
        name: '',
        phone: '',
        email: '',
        location: '',
        license: '',
        familiarity: '',
        experience: '',
        whyFit: '',
      });
      setShowModal(true); // Show the modal after successful submission
    } catch (error) {
      if (error.response) {
        setErrorMessage(error.response.data.message || 'An error occurred while submitting the application.');
        toast.error(error.response.data.message || 'An error occurred while submitting the application.');
      } else {
        setErrorMessage('An error occurred. Please try again.');
        toast.error('An error occurred. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setShowModal(false); // Close the modal
  };
      
  return (
  

        <div className='othercontainer'>
          <div  className='image' >
          <img src={riderImg} loading="lazy" alt="Rider" />

          </div>
         
          <h1>Join Our Team as a Delivery Personnel!</h1>
          <p>
            Are you a reliable, motivated, and punctual individual with a passion for customer service? 
            Do you enjoy navigating different locations and have a good knowledge of the routes across Nigeria? 
            We are excited to invite you to become a part of our team as a Delivery Personnel.
          </p>
    
          <h2>About the Role:</h2>
          <p>
            As a Delivery Personnel, you’ll play a key role in ensuring our products reach our valued customers quickly and safely. 
            We are looking for dedicated individuals who can handle deliveries efficiently, have excellent time management skills, 
            and are committed to providing a great customer experience.
          </p>
    
          <h3>What You’ll Do:</h3>
          <ul>
            <li>Deliver our company’s products across various locations in Nigeria</li>
            <li>Maintain accurate records of delivery status</li>
            <li>Ensure products are handled with care and delivered on time</li>
            <li>Provide friendly and courteous service to our customers</li>
          </ul>
    
          <h3>What We’re Looking For:</h3>
          <ul>
            <li>Valid driver’s license or equivalent</li>
            <li>Familiarity with major Nigerian roads and cities</li>
            <li>Strong communication and organizational skills</li>
            <li>Previous delivery experience is a plus</li>
            <li>Must be reliable, responsible, and a self-starter</li>
          </ul>
    
          <h3>Why Join Us?</h3>
          <ul>
            <li>Competitive pay and benefits</li>
            <li>Opportunity to work with a growing and supportive team</li>
            <li>Potential for growth within the company</li>
            <li>Flexibility in work schedule</li>
          </ul>
    
          <h2>Ready to Apply? Fill Out the Form Below!</h2>
    
          <Card>
        <form onSubmit={handleSubmit} className='--form-control --m'>
          <label>Full Name:</label><br />
          <input type="text" name="name" value={formData.name} onChange={handleChange} required /><br /><br />

          <label>Phone Number:</label><br />
          <input type="tel" name="phone" value={formData.phone} onChange={handleChange} required /><br /><br />

          <label>Email Address:</label><br />
          <input type="email" name="email" value={formData.email} onChange={handleChange} required /><br /><br />

          <label>Location (State):</label><br />
<select name="location" value={formData.location} onChange={handleChange} required>
  <option value="" disabled>Select your state</option>
  <option value="Abia">Abia</option>
  <option value="Adamawa">Adamawa</option>
  <option value="Akwa Ibom">Akwa Ibom</option>
  <option value="Anambra">Anambra</option>
  <option value="Bauchi">Bauchi</option>
  <option value="Bayelsa">Bayelsa</option>
  <option value="Benue">Benue</option>
  <option value="Borno">Borno</option>
  <option value="Cross River">Cross River</option>
  <option value="Delta">Delta</option>
  <option value="Ebonyi">Ebonyi</option>
  <option value="Edo">Edo</option>
  <option value="Ekiti">Ekiti</option>
  <option value="Enugu">Enugu</option>
  <option value="Gombe">Gombe</option>
  <option value="Imo">Imo</option>
  <option value="Jigawa">Jigawa</option>
  <option value="Kaduna">Kaduna</option>
  <option value="Kano">Kano</option>
  <option value="Katsina">Katsina</option>
  <option value="Kebbi">Kebbi</option>
  <option value="Kogi">Kogi</option>
  <option value="Kwara">Kwara</option>
  <option value="Lagos">Lagos</option>
  <option value="Nasarawa">Nasarawa</option>
  <option value="Niger">Niger</option>
  <option value="Ogun">Ogun</option>
  <option value="Ondo">Ondo</option>
  <option value="Osun">Osun</option>
  <option value="Oyo">Oyo</option>
  <option value="Plateau">Plateau</option>
  <option value="Rivers">Rivers</option>
  <option value="Sokoto">Sokoto</option>
  <option value="Taraba">Taraba</option>
  <option value="Yobe">Yobe</option>
  <option value="Zamfara">Zamfara</option>
  <option value="Federal Capital Territory">Federal Capital Territory</option>
</select><br /><br />


          <label>Do you have a valid driver’s license?</label><br />
          <input 
  type="radio" 
  name="license" 
  value="Yes" 
  checked={formData.license === 'Yes'} 
  onChange={handleChange} 
  required 
/> Yes  

<input 
  type="radio" 
  name="license" 
  value="No" 
  checked={formData.license === 'No'} 
  onChange={handleChange} 
/> No<br /><br />

          <label>How familiar are you with major cities and roads in your state?</label><br />
          <select name="familiarity" value={formData.familiarity} onChange={handleChange} required>
            <option value="" disabled>Select your familiarity level</option>
            <option value="Poor">Poor</option>
            <option value="Average">Average</option>
            <option value="Good">Good</option>
            <option value="Very Good">Very Good</option>
          </select><br /><br />

          <label>Do you have previous delivery experience?</label><br />
          <label>Do you have previous delivery experience?</label><br />
<input 
  type="radio" 
  name="experience" 
  value="Yes" 
  checked={formData.experience === 'Yes'} 
  onChange={handleChange} 
  required 
/> Yes
<input 
  type="radio" 
  name="experience" 
  value="No" 
  checked={formData.experience === 'No'} 
  onChange={handleChange} 
/> No<br /><br />

          <label>Tell us why you would be a good fit for this role:</label><br />
          <textarea name="whyFit" value={formData.whyFit} onChange={handleChange} required></textarea><br /><br />

          <button type="submit" className='--btn --btn-danger' disabled={loading}>
            {loading ? 'Submitting...' : 'Apply Now!'}
          </button>
        </form>
      </Card>
      {showModal && (
        <Modal message="Your application has been successfully submitted!" onClose={closeModal} />
      )}
        </div>
      );
    };
export default Rider