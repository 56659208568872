import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { RiCustomerService2Fill } from "react-icons/ri";
import { AiFillMail } from "react-icons/ai";

import { FaTiktok } from "react-icons/fa6";
import "./FooterLinks.scss";
import logoImg from "../../../assets/biopa.PNG";
import { Link } from "react-router-dom";
import masterCard from "../../../assets/mastercard.png";
import verve from "../../../assets/verve.png";
import visa from "../../../assets/Visa.png";
import interswitch from "../../../assets/interswitch.jpg";

const FooterLinks = () => {
  return (
    <div>
      <section className="contact-section">
        <div className="container contact">
          <h2>Let's Talk?</h2>
          <div className="contact-icon">
            <Link to="https://www.facebook.com/Blopia.com.ng">
              <FaFacebookF className="i" />
            </Link>
            <Link to="https://twitter.com/Blopia_ng">
              {" "}
              <FaTwitter className="i" />
            </Link>
            <Link to="https://www.tiktok.com/@blopia_ng">
              {" "}
              <FaTiktok className="i" />
            </Link>
            <Link to="https://www.instagram.com/blopia_ng/">
              {" "}
              <FaInstagram className="i" />
            </Link>
            <Link to="https://www.youtube.com/channel/UCodD0AB46nW56A2Oga1OdNA">
              {" "}
              <FaYoutube className="i" />
            </Link>
          </div>
          <div>
            <a href="tel:07025797147" className="btn btn-dark">
              Make an enquiry
            </a>
            <div className="text">
              <h4> Support: </h4>{" "}
              <a href="tel:09162926207">
                {" "}
                <RiCustomerService2Fill color="#fff"  size={14}/> 09162926207
              </a>{" "}
              <a href="tel:07025797147">
                {" "}
                <RiCustomerService2Fill color="#fff" size={14} /> 07025797147{" "}
              </a>
              <h6>
              <AiFillMail color="#fff" size={14} />  <a href="mailto:support@blopia.com.ng?subject=Support Inquiry">support@blopia.com.ng</a>
          </h6>
            </div>
          </div>
        </div>
      </section>
      <section className="footer-section">
        <div className="container footer">
          <div className="footer-logo">
            <img src={logoImg} alt="logo" />
          </div>

          <div className="footer-menu">
            <p className="link-heading">Useful Links</p>
            <ul className="nav-ul footer-links">
              <li>
                {/* <a href="#home">Service Center</a> */}
                <Link to="/services">Service Center</Link>
              </li>
              <li>
                <Link to="/return">Delivery options and timelines</Link>
              </li>
              <li>
                <Link to="/return">Returns & Refund Timeline</Link>
              </li>
            </ul>
          </div>

          <div className="footer-menu">
            <p className="link-heading">About Blopia</p>
            <ul className="nav-ul footer-links">
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/return">Returns</Link>
              </li>

              <li>
                <Link to="/terms-condition">Terms and Conditions</Link>
              </li>
              {/* <li>
                <a href="#home">Flash Sales</a>
              </li> */}
            </ul>
          </div>

          <div className="footer-menu">
            <p className="link-heading">Make money with Blopia</p>
            <ul className="nav-ul footer-links">
              <li>
                <a href="#home">Sell On Blopia</a>
              </li>
              <li>
                <a href="/rider">Become a Logistics Service Partner</a>
              </li>
              <li>
                <img src={masterCard} /> <img src={verve} />
              </li>
              <li>
                <img src={visa} /> <img src={interswitch} />
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FooterLinks;
