import axios from "axios";
import { toast } from "react-toastify";

// const BACKEND_URL = 'https://blopiaend.onrender.com';
const BACKEND_URL ='https://blopia.ng'
export const API_URL = `${BACKEND_URL}/api/v1/auth/`;

const register = async (userData) => {
  try {
    const response = await axios.post(API_URL + "register", userData, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      "An unexpected error occurred. Please try again.";
    toast.error(message);
    console.error("Registration Error:", error.response ? error.response.data : error.message);
    throw new Error(message); // Rethrow the error if needed
  }
};

const login = async (userData) => {
  try {
    const response = await axios.post(API_URL + "login", userData);
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      "An unexpected error occurred. Please try again.";
    toast.error(message);
    console.error("Login Error:", error.response ? error.response.data : error.message);
    throw new Error(message); // Rethrow the error if needed
  }
};

const logout = async () => {
  try {
    const response = await axios.get(API_URL + "logout");
    return response.data.msg;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      "An unexpected error occurred. Please try again.";
    toast.error(message);
    console.error("Logout Error:", error.response ? error.response.data : error.message);
    throw new Error(message); // Rethrow the error if needed
  }
};

const getLoginStatus = async () => {
  try {
    const response = await axios.get(API_URL + "getloginstatus");
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      "An unexpected error occurred. Please try again.";
    toast.error(message);
    console.error("Get Login Status Error:", error.response ? error.response.data : error.message);
    throw new Error(message); // Rethrow the error if needed
  }
};

const getUser = async () => {
  try {
    const response = await axios.get(API_URL + "getuser");
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      "An unexpected error occurred. Please try again.";
    toast.error(message);
    console.error("Get User Error:", error.response ? error.response.data : error.message);
    throw new Error(message); // Rethrow the error if needed
  }
};

const updateUser = async (userData) => {
  try {
    const response = await axios.patch(API_URL + "updateuser", userData);
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      "An unexpected error occurred. Please try again.";
    toast.error(message);
    console.error("Update User Error:", error.response ? error.response.data : error.message);
    throw new Error(message); // Rethrow the error if needed
  }
};

const updatePhoto = async (userData) => {
  try {
    const response = await axios.patch(API_URL + "updatephoto", userData);
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      "An unexpected error occurred. Please try again.";
    toast.error(message);
    console.error("Update Photo Error:", error.response ? error.response.data : error.message);
    throw new Error(message); // Rethrow the error if needed
  }
};

const loginWithGoogle = async (token) => {
  try {
    const response = await axios.post(API_URL + "google-login", { token });
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      "An unexpected error occurred. Please try again.";
    toast.error(message);
    console.error("Google Login Error:", error.response ? error.response.data : error.message);
    throw new Error(message); // Rethrow the error if needed
  }
};

const forgotPassword = async (email) => {
  try {
    const response = await axios.post(API_URL + "forgotpassword", { email });
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      "An unexpected error occurred. Please try again.";
    toast.error(message);
    console.error("Forgot Password Error:", error.response ? error.response.data : error.message);
    throw new Error(message); // Rethrow the error if needed
  }
};

// export const resetPassword = async (userData, resetToken) => {
//   try {
//     const response = await axios.put(
//       API_URL + "resetpassword",
//       { resetToken },
//       userData
//     );
//     return response.data;
//   } catch (error) {
//     const message =
//       (error.response && error.response.data && error.response.data.message) ||
//       error.message ||
//       "An unexpected error occurred. Please try again.";
//     toast.error(message);
//     console.error("Reset Password Error:", error.response ? error.response.data : error.message);
//     throw new Error(message); // Rethrow the error if needed
//   }
// };

export const resetPassword = async (userData, resetToken) => {
  try {
    const response = await axios.put(
      `${API_URL}/auth/resetpassword/${resetToken}`, // Append the resetToken here
      userData
    );
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      "An unexpected error occurred. Please try again.";
    toast.error(message);
    throw new Error(message); // Rethrow for further handling
  }
};

const authService = {
  register,
  login,
  logout,
  getLoginStatus,
  getUser,
  updateUser,
  updatePhoto,
  loginWithGoogle,
  forgotPassword,
  resetPassword,
};

export default authService;
