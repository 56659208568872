import React, { useEffect, Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './components/layout/Layout';
import { getloginstatus, getuser } from './store/auth/authIndex';
import WhatsAppButton from './components/whatsapp/WhatsAppButton';
import LOGO from './assets/BLOPIA.PNG'
import './App.css'
import Forgot from './pages/auth/Forgot';
import Reset from './pages/auth/Reset';
import Rider from './pages/others/Rider';
import UseFacebookPixel from './hooks/facebookepixel/useFacebookPixel';
import ScrollToTop from './components/common/ScrollToTop';
// Lazy load pages
const HomePage = lazy(() => import('./pages/home-page/HomePage'));
const Login = lazy(() => import('./pages/auth/Login'));
const Register = lazy(() => import('./pages/auth/Register'));
const Profile = lazy(() => import('./pages/profile/Profile'));
const AdminPage = lazy(() => import('./pages/admin/AdminPage'));
const AdminOnlyRoute = lazy(() => import('./components/admin/hideAdmin/AdminOnlyRoute'));
const NotFound = lazy(() => import('./pages/404/NotFound'));
const ShopPage = lazy(() => import('./pages/shopPage/ShopPage'));
const ProductDetails = lazy(() => import('./components/shop/productDetails/ProductDetails'));
const CartPage = lazy(() => import('./pages/cartPage/CartPage'));
const CheckoutDetailsPage = lazy(() => import('./pages/CheckoutDetailsPage'));
const Checkout = lazy(() => import('./components/checkout/Checkout'));
const CheckoutSuccessPage = lazy(() => import('./pages/checkoutSuccessPage/CheckoutSuccessPage'));
const OrderDetailsPage = lazy(() => import('./pages/orderDetailsPage/OrderDetailsPage'));
const OrderHistoryPage = lazy(() => import('./pages/orderHistoryPage/OrderHistoryPage'));
const CheckoutWithFlutterwave = lazy(() => import('./components/checkout/checkoutWithFlutterwave/CheckoutWithFlutterwave'));
const CheckoutWithPaypal = lazy(() => import('./components/checkout/checkoutWithPaypal/CheckoutWithPaypal'));
const WalletPage = lazy(() => import('./pages/walletPage/WalletPage'));
const CheckoutPayOnDelivery = lazy(() => import('./components/checkout/checkoutondelivery/CheckoutPayOnDelivery'));
const Terms = lazy(() => import('./pages/others/Terms'));
const CheckoutWithWallet = lazy(() => import('./components/checkout/checkoutWithwallet/CheckoutWithWallet'));
const About = lazy(() => import('./pages/others/About'));
const ServiceCenter = lazy(() => import('./pages/others/ServiceCenter'));
const Return = lazy(() => import('./pages/others/Return'));

function App() {
  axios.defaults.withCredentials = true;
  ;  

  const dispatch = useDispatch();
  const { isLoggedIn, user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getloginstatus());
  }, [dispatch]);

  useEffect(() => {
    if (isLoggedIn && user === null) {
      dispatch(getuser());
    }
  }, [dispatch, isLoggedIn, user]);

  return (
    <>
      <ScrollToTop />
      <Layout>
        <ToastContainer />
        <WhatsAppButton/>
        <UseFacebookPixel  /> 
        <Suspense fallback={<div className='--flex-center --100vh App'>
          <img src={ LOGO } alt='loader Img'/>
        </div>}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/shop" element={<ShopPage />} />
            <Route path="/product-details/:id" element={<ProductDetails />} />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/admin/*" element={<AdminOnlyRoute><AdminPage /></AdminOnlyRoute>} />
            <Route path="/checkout-success" element={<CheckoutSuccessPage />} />
            <Route path="/order-history" element={<OrderHistoryPage />} />
            <Route path="/order-details/:id" element={<OrderDetailsPage />} />
            <Route path="/checkout-details" element={<CheckoutDetailsPage />} />
            <Route path="/checkout-stripe" element={<Checkout />} />
            <Route path="/checkout-flutterwave" element={<CheckoutWithFlutterwave />} />
            <Route path="/checkout-paypal" element={<CheckoutWithPaypal />} />
            <Route path="/checkout-delivery" element={<CheckoutPayOnDelivery />} />
            <Route path="/checkout-wallet" element={<CheckoutWithWallet />} />
            <Route path="/wallet" element={<WalletPage />} />
            <Route path="/terms-condition" element={<Terms />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<ServiceCenter />} />
            <Route path="/return" element={<Return />} />
            <Route path='/forgot' element={<Forgot/>}/>
            <Route path='/reset' element={<Reset/>}/>
            <Route path='/resetpassword/:token' element={<Reset/>}/>
            <Route path='/rider' element={<Rider/>}/>
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </Layout>
    </>
  );
}

export default App;
