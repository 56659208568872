import axios from "axios";

const API_URL = "http://localhost:10000/api/v1/auth/";

const getUsers = async () => {
  const response = await axios.get(API_URL+'getusers');
  return response.data;
};

const deleteUser = async (id) => {
  const response = await axios.delete(`${API_URL}${id}`);
  return response.data;
};

const getSingleUser = async (id) => {
  const response = await axios.get(`${API_URL}${id}`);
  return response.data;
};

const updateUser = async (id, formData) => {
  const response = await axios.patch(`${API_URL}${id}`, formData);
  return response.data;
};

const userService = {
  getUsers,
  deleteUser,
  getSingleUser,
  updateUser,
};

export default userService;
