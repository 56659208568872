// src/store/slider/sliderSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchSliderData = createAsyncThunk(
  'slider/fetchSliderData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch('https://blopia.ng/api/v1/slider/get-sliders');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Raw API Response:', data); // Debug log

      // Check if data has the expected structure
      if (!data || !data.sliders) { // Assuming the API returns { sliders: [] }
        console.error('Unexpected data structure:', data);
        throw new Error('Invalid data structure received from API');
      }

      // Return the sliders array
      return data.sliders;
      
    } catch (error) {
      console.error('Slider fetch error details:', error);
      return rejectWithValue(error.message);
    }
  }
);
// Create slider
export const createSlider = createAsyncThunk('slider/createSlider', async (formData) => {
  const response = await axios.post('https://blopia.ng/api/v1/slider/create-slider', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
});

// Delete slider
export const deleteSlider = createAsyncThunk('slider/deleteSlider', async (id) => {
  await axios.delete(`https://blopia.ng/api/v1/slider/delete-slider/${id}`);
  return id;
});

const sliderSlice = createSlice({
  name: 'slider',
  initialState: {
    sliders: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSliderData.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchSliderData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.sliders = action.payload;
        state.error = null;
      })
      .addCase(fetchSliderData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createSlider.fulfilled, (state, action) => {
        state.sliders.push(action.payload);
      })
      .addCase(deleteSlider.fulfilled, (state, action) => {
        state.sliders = state.sliders.filter((slider) => slider._id !== action.payload);
      });
  },
});

export default sliderSlice.reducer;
