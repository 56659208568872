import axios from "axios";

const BACKEND_URL = 'https://blopia.ng';
//const BACKEND_URL ='http://localhost:10000'

export const API_URL = `${BACKEND_URL}/api/v1/order/`;


//createOrders:
const createOrder = async (formData)=>{
    const response = await axios.post(API_URL + "createOrder" ,formData);
    return response.data
};

//getOrders:
const getOrders = async()=>{
    const response = await axios.get(API_URL + "getOrders");
    return response.data
};

//getSingleOrder:
const getSingleOrder = async(id) => {
    try {
        if (!id) {
            throw new Error('Order ID is required');
        }
        const response = await axios.get(API_URL + id);
        if (!response.data) {
            throw new Error('Order not found');
        }
        return response.data;
    } catch (error) {
        if (error.response?.status === 404) {
            throw new Error('Order not found');
        }
        throw error;
    }
};

//updateOrderStatus:
const updateOrderStatus = async(id,formData)=>{
    const response = await axios.patch(API_URL + id , formData);
    return response.data
}

const orderService = {createOrder,getOrders,getSingleOrder,updateOrderStatus};
export default orderService;