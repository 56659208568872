import { useEffect } from "react";
import { useLocation } from 'react-router-dom';

const UseFacebookPixel = () => {
  const location = useLocation();
  useEffect(() => {
    try {
      // Check if fbq is already initialized to avoid duplication
      if (window.fbq) return;

      (function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)})(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');

      setTimeout(() => {
        if (window.fbq) {
          window.fbq('init', '587972660285660');
          window.fbq('track', 'PageView');
          window.fbq('track', 'ViewContent');
          window.fbq('track', 'AddToCart');

        }
      }, 1000);
    } catch (error) {
      console.error('Facebook Pixel Error:', error);
    }
  }, [location.pathname]);
};

export default UseFacebookPixel;
